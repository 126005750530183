import { Link } from "gatsby";
import * as styles from "./author-list.module.css";
import React from "react";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

function AuthorList({ items, title }) {
  return (
    <div className={styles.root}>
      <h2 className={styles.headline}>{items.count > 1 ? title+"s" : title}</h2>
      <ul className={styles.list}>
        {items.map(({ author, _key }) => {
          const authorName = author && author.name;
          const slug = author && author.slug && author.slug.current
          const authorPath = `/${slug}`
          return (
            <Link to={authorPath} className={styles.authorLink}>
              <li key={_key} className={styles.listItem}>
                <div>
                  <div className={styles.avatar}>
                    {author && author.image && author.image.asset && (
                      <img
                        src={imageUrlFor(buildImageObj(author.image))
                          .width(100)
                          .height(100)
                          .fit("crop")
                          .url()}
                        alt=""
                      />
                    )}
                  </div>
                </div>
                <div>
                  <div>{authorName || <em>Missing name</em>}</div>
                </div>
              </li>
            </Link>
          );
        })}
      </ul>
    </div>
  );
}

export default AuthorList;
